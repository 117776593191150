import React,{useState,useContext} from 'react';
import { AppContext } from '../Drinkereez';
import ShowShipments from './ShowShipments';
import ShowShipment from './ShowShipment';
//import {log} from '../../Log';

export default function ShipmentPage() {
    const {client} = useContext(AppContext);
    const [shipment,setShipment] = useState(); // eslint-disable-line no-unused-vars
    const [showShipmentList, setShowShipmentList] = useState(true);
    const [showShipment,setShowShipment] = useState(false); // eslint-disable-line no-unused-vars
    const inEU = !client.company?.nonEU;

    function shipmentSelected (shipment) {
        setShowShipmentList(false);
        setShowShipment(true);
        setShipment(shipment);       
    }

    function stopShowShipment () {
        setShowShipmentList(true);
        setShowShipment(false);
    }

    function shipmentSaved () {  // eslint-disable-line no-unused-vars
        stopShowShipment();
    }

    return (
        <div className="ShipmentPage">
            {
                showShipmentList && <ShowShipments shipmentSelected={shipmentSelected} asConsignee={false}/>
            }
            {
                inEU && showShipmentList && <ShowShipments shipmentSelected={shipmentSelected} asConsignee={true}/>
            }
            {
                showShipment && <ShowShipment shipment={shipment} shipmentSaved={shipmentSaved} stopShowShipment={stopShowShipment}/>
            }
        </div>
    )
}
