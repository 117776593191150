import React from 'react';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import resources from '../../resources/lists/dropdownlist.json';
import PropTypes from 'prop-types';

ContactPerson.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    eMail: PropTypes.string,
    phoneNumber: PropTypes.string,
    languageCode: PropTypes.string,
    header: PropTypes.string,
    readOnly: PropTypes.bool,
    updateContactPerson: PropTypes.func.isRequired
};

export default function ContactPerson(props) {
    const { firstName, lastName, eMail, phoneNumber, languageCode, header, readOnly, updateContactPerson, decimalSeparator } = props;

    function changeHandler(e) {
        const { name, value } = e;
        updateContactPerson({ [name]: value });
    }

    return (
        <div className="ContactPerson mb-5">
            <FormLineParagHeader label={header}/>
            <div className='row'>
                <FormLineInput col={3} readOnly={readOnly} label="label.firstName" name="firstName" value={firstName} onChange={changeHandler} required={true}/>
                <FormLineInput col={6} readOnly={readOnly} label="label.lastName" name="lastName" value={lastName} onChange={changeHandler} required={true}/>
                <FormLineSelect col={3} list={resources.languageCodes} readOnly={readOnly} label="label.language" name="languageCode" value={languageCode} onChange={changeHandler} required={true}/>
            </div>
            <div className='row'>
                <FormLineInput col={6} readOnly={readOnly} label="label.eMail" name="eMail" value={eMail} onChange={changeHandler} required={true}/>
                <FormLineInput col={3} type="phoneNumber" readOnly={readOnly} label="label.phoneNumber" name="phoneNumber" value={phoneNumber} onChange={changeHandler} required={true}/>
                <FormLineSelect col={3} list={resources.decimalSeparators} readOnly={readOnly} label="label.decimalSeparator" name="decimalSeparator" value={decimalSeparator} onChange={changeHandler} required={true}/>
            </div>
        </div>
    );
}
