import React, {useContext} from 'react';
import { ProductContext } from '../ShowProducts';
import TableRowCell from '../../layout/tables/TableRowCell';
import { getLabelFromList } from '../../GeneralFunc';
import resources from '../../../resources/lists/dropdownlist.json'
import FormLineParagHeader from '../../layout/forms/FormLineParagHeader';
import { AppContext } from '../../Drinkereez';
import FormButton from '../../layout/forms/FormButton';
import TableLayout from '../../layout/tables/TableLayout';
import PropTypes from 'prop-types';

ShowSpiritProducts.propTypes = {
    spiritProducts: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ShowSpiritProducts(props) {
    const {user} = useContext(AppContext);
    const admin = user.userType === 'USER_TYPE_ADMIN';
    const {spiritProducts} = props;
    const {viewRecord, addRecord} = useContext(ProductContext);
    const columns=["label.productName","label.type","label.colour","label.ageStatement","label.volumes"];

    function getVolumes (prod) {
        let volumes="";
        let first=true;
        prod.bottleTypes.map((vol) => {
            if (first) {
                volumes = vol.volume;
                first = false;
            } else {
                volumes = volumes + ", " + vol.volume;
            }
            return "";
        });
        return volumes;
    }

    function onClickTable (e) {
        viewRecord(e.currentTarget.id,'SPIRIT');
    }

    function addRecordHandler () {
        addRecord('SPIRIT');
    }

    function displayData () {
        if (!spiritProducts) return('');
        const ret = spiritProducts.map((product) => {
            return (
                <tr key={product.id}>
                    <TableRowCell first="true" value={product.productName} onClick={onClickTable} id={product.id}/>
                    <TableRowCell label={getLabelFromList(resources.SpiritTypes,product.subType)}/>
                    <TableRowCell label={product.colour}/>
                    <TableRowCell value={product.vintage}/>
                    <TableRowCell value={getVolumes(product)}/>
                </tr>
            )
        }) 
        return (ret);
    }

    return (
        <div className="ShowWineProducts">
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.spirit"}/>
                <div className='col-md-2'>
                    {
                        !admin && <FormButton onClick={addRecordHandler} label='button.add'/>
                    }
                </div>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>
    )
}
