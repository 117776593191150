import React, {useContext,useEffect,useState} from 'react'
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import { getLabelFromList } from '../GeneralFunc';
import resources from '../../resources/lists/dropdownlist.json'
//import { useTranslation } from "react-i18next";
import TableRowCell from '../layout/tables/TableRowCell';
import { CallGetAllXml } from './CallXml';
import TableLayout from '../layout/tables/TableLayout';
import FormButton from '../layout/forms/FormButton';
import PropTypes from 'prop-types';

ShowXMLs.propTypes = {
    xmlSelected: PropTypes.any, // Replace 'any' with the specific type you expect
    addXML: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ShowXMLs(props) {
    const {xmlSelected,addXML} = props;
//    const {t} = useTranslation();
    const {login} = useContext(AppContext);
    const columns = ["label.name","label.type","label.xmlUploadDate","label.state"];
    const [xmls,setXmls] = useState ();

    useEffect(() => {
        getAllXMLs();
      }, []);

    function getAllXMLs() {
        log.debug("ShowXMLs - getAllXMLs");
        CallGetAllXml(login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug(response);
            setXmls(response.xmlDatas);
        });
    }  

    function onClickTable (e) {
        log.debug("clicked : " + e.currentTarget.id);
        xmlSelected(xmls.find(({id}) => id === e.currentTarget.id));
    }

    function displayData () {
        if (!xmls) return('');
        const ret = xmls.map((xmlData) => {
            return (
                <tr key={xmlData.id}>
                    <TableRowCell first="true" value={xmlData.name} onClick={onClickTable} id={xmlData.id}/>
                    <TableRowCell label={getLabelFromList(resources.ExciseXMLTypes,xmlData.type)}/>
                    <TableRowCell value={xmlData.uploadDate}/>
                    <TableRowCell label={getLabelFromList(resources.XMLStates,xmlData.state)}/>
                </tr>
            )
        }) 
        return (ret);
    }

    return (
        <div className="ShowXmls">
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.xmls"}/>
                <div className='col-md-2'>
                    <FormButton onClick={addXML} label='button.add'/>
                </div>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>   
    )
}