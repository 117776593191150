import React from 'react'
//import { useTranslation } from "react-i18next";
import FormButton from './layout/forms/FormButton';
import PropTypes from 'prop-types';

LoginNavBar.propTypes = {
    navOptionClicked: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function LoginNavBar(props) {
//    const {t} = useTranslation();
    const {navOptionClicked} = props;

    function optionClicked (e) {
        navOptionClicked(e.target.id);
    }

    return (
        <div className="center">
            <FormButton onClick={optionClicked} id="register" label='button.register'/>
            <FormButton onClick={optionClicked} id="login" label='button.login'/>
        </div>
    )
}