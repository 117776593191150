import React, { useContext,useEffect,useState,useImperativeHandle,forwardRef,useCallback } from 'react'
//import { useTranslation } from "react-i18next";
import { AppContext } from '../Drinkereez';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import TableLayout from '../layout/tables/TableLayout';
import TableRowCell from '../layout/tables/TableRowCell';
import resources from '../../resources/lists/dropdownlist.json';
import { getLabelFromList,openWindow } from '../GeneralFunc';
import { CallGetClientServicesFromUser } from './CallClient';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
//import {log} from '../../Log';
import PropTypes from 'prop-types';

const ClientServicesListUser = forwardRef(({ showService }, ref) => {
    //const { t } = useTranslation();
    const { login } = useContext(AppContext);
    const columns = ["label.service","label.comment","label.contract","label.state"];
    const [clientServices,setClientServices] = useState ();
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});

    const callGetAllClientServices = useCallback(() => {
        CallGetClientServicesFromUser(login, (response) => {
            if (response.err) {
                if (response.errCode === "GDAO-101") {
                    // Handle specific error
                } else {
                    setError({ errorMessage: response.err, errorJSON: { login } });
                }
            } else {
                setClientServices(response.clientServices);
            }
        });
    }, [login]); // Dependency array ensures the function changes only if `login` changes


    useEffect(() => {
        callGetAllClientServices();
      }, [callGetAllClientServices]);

    function openContract (clientService) {
        openWindow(clientService.contract.url);
    }

    useImperativeHandle(ref, () => ({
        reloadData: callGetAllClientServices
    }));

    function displayData () {
        if (clientServices) {
            return clientServices.map((clientService) => (
                <tr key={clientService.id}>
                    <TableRowCell label={getLabelFromList(resources.drinkereezServices, clientService.type)} onClick={() => showService(clientService)} />
                    <TableRowCell label={clientService.comment} />
                    <TableRowCell label={clientService.contract.name} onClick={() => openContract (clientService)} />
                    <TableRowCell label={getLabelFromList(resources.drinkereezServiceStates, clientService.state)} />
                </tr>
            ));
        }
    }

    return (
        <div>
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.servicesList"}/>
            </div>
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>
    )
})

ClientServicesListUser.propTypes = {
    showService: PropTypes.func.isRequired // Expecting a function type
};

export default ClientServicesListUser;