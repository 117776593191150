import { log } from '../Log';
import { useContext } from 'react';
import { AppContext } from './Drinkereez'

export const useDomain = () => {
  if (typeof window === 'undefined') return ''; // Ensure compatibility with server-side rendering
  const currentUrl = window.location.href;
  const start = currentUrl.indexOf('//') + 2;
  const end = currentUrl.indexOf('/', start);
  const domain = currentUrl.substring(start, end);
  log.debug(`currentUrl: ${currentUrl}, domain: ${domain}`);
  return domain;
};

export const getRecordsByVariableName = (recordList, variableName, targetValue) => {
    return recordList.filter(record => record[variableName] === targetValue);
  };

// Add or update a record in a table
export const addOrUpdateRecord = (table, newRecord) => {
  if (!newRecord.id) return [...table, newRecord];
  const existingIndex = table.findIndex(record => record.id === newRecord.id);
  if (existingIndex !== -1) {
      const updatedRecords = [...table];
      updatedRecords[existingIndex] = newRecord;
      return updatedRecords;
  }
  return [...table, newRecord];
};

// Create a concatenated list of IDs
export const makeList = (records, maxDisplayed) => {
  const orderedList = records.slice().sort();
  const visibleList = orderedList.slice(0, maxDisplayed).join(', ');
  const remainingCount = orderedList.length - maxDisplayed;
  return remainingCount > 0 ? `${visibleList}, ... ${remainingCount} more` : visibleList;
};

/*export function makeList (records,maxDisplayed) {
    const orderedList = records.slice().sort();
    const concatenatedIds = orderedList
      .slice(0, maxDisplayed)
      .map(record => record)
      .join(', ');
  
    const additionalIdsCount = orderedList.length - maxDisplayed;
    const additionalIdsText = additionalIdsCount > 0 ? `, ... ${additionalIdsCount} more` : '';
  
    return concatenatedIds + additionalIdsText;
}*/

// Create a linked list from IDs and total list
export const createLinkedList = (idList, totalList, dynamicFieldName) => {
  if (!totalList) return [];
  const idToNameMap = Object.fromEntries(
      totalList.map(record => [record.id, record[dynamicFieldName]])
  );
  return idList.map(id => idToNameMap[id]);
};

// Sort a list by a specific field
export const orderByField = (list, fieldName) =>
  [...(list || [])].sort((a, b) => (a[fieldName] < b[fieldName] ? -1 : a[fieldName] > b[fieldName] ? 1 : 0));

/*export function orderByField  (list, fieldName) {
    if (!list) return;
    const sortedRecords = [...list].sort((a, b) => {
        if (a[fieldName] < b[fieldName]) return -1;
        if (a[fieldName] > b[fieldName]) return 1;
        return 0;
      });
      return sortedRecords;
}*/

// Transform records into value-label pairs
export const transformRecords = (records, dynamicFieldName) =>
  (records || []).map(record => ({
      value: record.id,
      label: record[dynamicFieldName],
  }));

// Get a list slice by percentage
export const getListByPercentage = (list, percentage, difference) => {
  const start = Math.floor(list.length * (percentage - difference) / 100);
  const end = Math.floor(list.length * percentage / 100);
  return list.slice(start, end);
};

// Extract values by field name
export const extractValues = (records, dynamicFieldName) => (records || []).map(record => record[dynamicFieldName]);

// Convert text to a number
export const toNumber = text => (text && text !== '' ? Number(text) : 0);

// Find a label by value in a list
export const getLabelFromList = (list, val) => list.find(({ value }) => value === val)?.label || '';

// Remove "public/" prefix from URL
export const removePublicPrefixURL = url => url && url.startsWith('public/') ? url.slice(7) : url;

export const openWindow = (url) => {
  log.debug("open window, original url : " + url);
  const newUrl = process.env.REACT_APP_FILEURL + removePublicPrefixURL(url);
  log.debug("open window : " + newUrl);
  window.open(newUrl, '_blank');
}

// Custom hook for formatting decimal values
export const useLayoutDecimal = (value) => {
  const { user } = useContext(AppContext);

  if (user === null || user === undefined) return value;
    
  if( value === null || value === undefined || value === '') return '';
  
  const stringValue = String(value);
  const decimalSeparator = user.person.decimalSeparator;

  return decimalSeparator === 'DECIMAL_SEPARATORS_COMMA'
      ? stringValue.replace('.', ',')
      : stringValue;
};

/*export function layoutDecimal (value,minimumFractionDigits,maximumFractionDigits,decimalSeperator) {
  if (value === null) return '';
        
  const formattedValue = new Intl.NumberFormat('default', {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
  }).format(value);

  if (decimalSeperator === "DECIMAL_SEPARATORS_COMMA") {
    return formattedValue.replace('.', ',');
  } else {
    return formattedValue;
  }  
}*/