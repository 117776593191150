import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
import shipmentTest from '../../resources/test/shipmentTest.json';

export function CallgetAllShipments (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallgetAllShipments");

    const body = { 
        req : {...login,action: 13}
    }

    log.debug(body);
    log.debug("post " + testMode);

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (shipmentTest.shipments);
    }
    else {
        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}

export function CallgetAllConsigneeShipments (login,companyId,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallgetAllConsigneeShipments");

    const body = { 
        req : {...login,id: companyId,action: 15}
    }

    log.debug(body);
    log.debug("post " + testMode);

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (shipmentTest.shipments_consignee);
    }
    else {
        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}

export function CallgetAllConsignorShipments (login,companyId,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallgetAllConsignorShipments");

    const body = { 
        req : {...login,id: companyId,action: 16}
    }

    log.debug(body);
    log.debug("post " + testMode);

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (shipmentTest.shipments_consignor);
    }
    else {
        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}

export function CallSaveShipment (login,shipment,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';    
    log.debug("send JSON CallSaveShipment");

    // Clean temporary IDs
    const cleanedShipment = {
        ...shipment,
        items: shipment.items.map((item) =>
            item.id.startsWith("temp") ? { ...item, id: "" } : item
        ),
    };

    const body = { 
        req: { ...login, action: 14 },
        ...cleanedShipment
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        if (!shipment.id) {
            // Assign a random number as ID (example: a random number between 100000 and 999999)
            shipment.id = Math.floor(Math.random() * 900000) + 100000;
        }
        responseFunc (shipment);
        //responseFunc ({err : "error.server_return_error", errCode : "213548"});
    }
    else {
        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }    
}