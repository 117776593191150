import React, {useEffect,useState} from 'react'
import {log} from '../../Log';
import { makeList,createLinkedList } from '../GeneralFunc';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import TableRowCell from '../layout/tables/TableRowCell';
import TableLayout from '../layout/tables/TableLayout';
import FormButton from '../layout/forms/FormButton';
import { getLabelFromList } from '../GeneralFunc';
import resources from '../../resources/lists/dropdownlist.json'
import PropTypes from 'prop-types';

ShowTarifNationalCodes.propTypes = {
    tarifSelected: PropTypes.any, // Replace 'any' with the specific type you expect
    addTarif: PropTypes.any, // Replace 'any' with the specific type you expect
    tarifs: PropTypes.any, // Replace 'any' with the specific type you expect
    natCodes: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ShowTarifNationalCodes(props) {
    const {tarifSelected,addTarif,tarifs,natCodes} = props;
    const columns = ["label.tarifType","label.calculationBase","label.value","label.natCodes"];
    const [forceRender, setForceRender] = useState(false); // eslint-disable-line no-unused-vars
    log.debug("in ShowTarifNationalCodes");

    useEffect(() => { 
        log.debug("ShowTarifNationalCodes updated");
        setForceRender(prevValue => !prevValue); 
    },[tarifs]);

    function onClickTable (e) {
        log.debug("clicked : " + e.target.id);
        log.debug(e);
        tarifSelected(tarifs.find(({id}) => id === e.currentTarget.id));
    }

    function getNatCodes (tar) {
        return makeList(createLinkedList(tar.natCodes,natCodes,"natCode"),5);
    }

    function displayData () {
        if (!tarifs) return('');
        const ret = tarifs.map((tar) => {
            return (
                <tr key={tar.id}>
                    <TableRowCell first="true" label={getLabelFromList(resources.tarifTypes,tar.tarifType)} onClick={onClickTable} id={tar.id}/>
                    <TableRowCell label={getLabelFromList(resources.calculationBases,tar.calculationBase)} onClick={onClickTable} id={tar.id}/>
                    <TableRowCell value={tar.value ? tar.value : "0"}/>
                    <TableRowCell value={getNatCodes(tar)}/>
                </tr>
            )
        }) 
        return (ret);
    }

    return (
        <div className="ShowTarifNationalCodes">
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.tarifNationalCodes"}/>
                <div className='col-md-2'>
                    {
                        <FormButton onClick={addTarif} label='button.add'/>
                    }
                </div>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>   
    )
}