import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLabelFromList } from '../../GeneralFunc';
import {log} from '../../../Log';

const FormLineSelect = ({name,value,list = [],label,readOnly = false,selectLabel,
    placeholder = 'value',onChange,updateObject,freeList = false,col = 12, order=false}) => {
  const { t } = useTranslation();
  const className = `form-floating my-2 col-md-${col}`;

  const handleChange = (e) => {
    if (onChange) {
        onChange({ name, value: e.target.value });
    } else if (updateObject) {
        updateObject({ [name]: e.target.value });
    }
  };

  if (!value && readOnly) return null;

  const renderDropdownOption = ({ value, label }) => (
    <option key={value} value={value}>
      {label}
    </option>
  );

  //log.debug("order : " + order);

  const transformedList = freeList ? list : list.map(item => ({value: item.value,label: t(item.label)}));

  const sortedList = order ? [...transformedList].sort((a, b) => a.label.localeCompare(b.label)) : transformedList;

  function getValueFromList () {
    return freeList ? null : t(getLabelFromList(list,value)); // TODO for freelist
  }

  return (
    <div className={className}>
      {readOnly ? (
        <input className="form-control border-0" id={`floatingSelect-${name}`} readOnly name={name} value={getValueFromList()} placeholder={placeholder}/>
      ) : (
        <select className="form-select border-0" name={name} id={`floatingSelect-${name}`} value={value} onChange={handleChange} required>
          {(!value || value === '') && (
            <option value="">
              {t('label.selectA')} {t(selectLabel || label)}
            </option>
          )}
          {sortedList.map(renderDropdownOption)}
        </select>
      )}
      <label className="mx-1" htmlFor={`floatingSelect-${name}`}>
        {t(label)}
      </label>
    </div>
  );
};

FormLineSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  label: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  selectLabel: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  updateObject: PropTypes.func,
  freeList: PropTypes.bool,
  col: PropTypes.number
};

export default FormLineSelect;

/*import React from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

FormLineSelect.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })),
    label: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    selectLabel: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    freeList: PropTypes.bool,
    col: PropTypes.number
};

export default function FormLineSelect({ name, label, value, list, onChange, selectLabel, readOnly, placeholder, freeList, col = 12 }) {
    const { t } = useTranslation();
    const placeH = placeholder || 'value';
    const classN = `form-floating my-2 col-md-${col}`;

    const changeHandler = e => {
        onChange({ name, value: e.target.value });    
    };

    if (!value && readOnly) {
        return null;
    }

    const renderDropdownOption = (element) => {
        return (
            <option key={element.value} value={element.value}>
                {freeList ? element.label : t(element.label)}
            </option>
        );
    };

    return (
        <div className={classN}>
            {readOnly ? (
                <input
                    className="form-control border-0"
                    id={`floatingSelect-${name}`}
                    readOnly={true}
                    name={name}
                    value={t(label)} // Assuming `label` is being translated if it's used as a value
                    placeholder={placeH}
                />
            ) : (
                <select
                    className="form-select border-0"
                    name={name}
                    id={`floatingSelect-${name}`}
                    value={value}
                    onChange={changeHandler}
                    required
                >
                    {(!value || value === "") && (
                        <option defaultValue value="">
                            {t('label.selectA')} {t(selectLabel || label)}
                        </option>
                    )}
                    {list.map(renderDropdownOption)}
                </select>
            )}
            <label className="mx-1" htmlFor={`floatingSelect-${name}`}>{t(label)}</label>
        </div>
    );
}
 /*

import React from 'react';
import { useTranslation } from "react-i18next";
import getLabelFromList from '../../General/getLabelFromList';
//import {log} from '../../../Log';
import PropTypes from 'prop-types';

FormLineSelect.propTypes = {
    name: PropTypes.any, // Replace 'any' with the specific type you expect
    value: PropTypes.any, // Replace 'any' with the specific type you expect
    list: PropTypes.any, // Replace 'any' with the specific type you expect
    label: PropTypes.any, // Replace 'any' with the specific type you expect
    readOnly: PropTypes.any, // Replace 'any' with the specific type you expect
    selectLabel: PropTypes.any, // Replace 'any' with the specific type you expect
    placeholder: PropTypes.any, // Replace 'any' with the specific type you expect
    onChange: PropTypes.any, // Replace 'any' with the specific type you expect
    freeList: PropTypes.any, // Replace 'any' with the specific type you expect
    col: PropTypes.any // Replace 'any' with the specific type you expect
};
export default function FormLineSelect (props) {
    const {t} = useTranslation();
    const {name,label,value,list,onChange,selectLabel,readOnly,placeholder,freeList} = props;
    const selLabel = selectLabel ? selectLabel : label;
    const placeH = placeholder ? placeholder : 'value';
    const col = props.col ? props.col : 12;
    const classN = "form-floating my-2 col-md-" + col;
    //const required = props.required && props.required.toUpperCase()==="TRUE";

    //log.debug("FormLineSelect");
    //log.debug(list);

    function changeHandler (e) {
        onChange({name: name, value: e.target.value});    
    }

    /*
    return (
        <div className="form-group row my-2">
            <label className="col-sm-3 col-form-label text-md-left text-dark">{getLabel(props.label)}</label>
            <div className="col-sm-9">
                {
                    props.readOnly ?
                    <label className="col-form-label text-md-left text-dark">{props.list.map((element) => {
                        if (element.value===props.value) return(getLabel(element.label))})}</label>
                    :
                    <select className="form-control" name="value" value={props.value} onChange={changeHandler} required>
                        {
                            props.value==="" ? <option defaultValue value=''>{getLabel("label.selectA")} {getLabel(selectLabel)} ...</option> : null
                        }
                        {
                            props.list.map((element) => {
                                return(<option key={element.value} value={element.value}>{getLabel(element.label)}</option>);
                            })
                        }
                    </select>
                }
            </div>
        </div>
    );  
    *//*

    
   function dropdownElement (element) {
    if (!freeList) return (<option key={element.value} value={element.value}>{t(element.label)}</option>);
    return (<option key={element.value} value={element.value}>{element.label}</option>);
   }

   if (!value && readOnly) {
    return (<></>);
    }

    return (
        <div className={classN}>
            { readOnly ? <input className="form-control border-0" id="floatingSelect" readOnly={readOnly} name="value" value={t(getLabelFromList(list,value))} placeholder={placeH}/>:
            <select className="form-select border-0" name="value" id="floatingSelect" value={value} onChange={changeHandler} required>
                {
                    (!value || value==="") && <option defaultValue value=''>{t("label.selectA")} {t(selLabel)} ...</option>
                }
                {
                    list && list.map((element) => {
                        return dropdownElement(element);
                    })
                }
            </select>}
            <label className="mx-1" htmlFor="floatingSelect">{t(label)}</label>
        </div>
    );  
}*/