import React, {useContext,useEffect,useState} from 'react'
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
//import { useTranslation } from "react-i18next";
import TableRowCell from '../layout/tables/TableRowCell';
import { CallgetAllOrders } from './CallOrder';
import TableLayout from '../layout/tables/TableLayout';
import { getLabelFromList } from '../GeneralFunc';
import resources from '../../resources/lists/dropdownlist.json'
import PropTypes from 'prop-types';

ShowOrders.propTypes = {
    orderSelected: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ShowOrders(props) {
    const {orderSelected} = props;
//    const {t} = useTranslation();
    const {login} = useContext(AppContext);
    const columns = ["label.orderTime","label.totalAmount","label.totalPaid","label.state","label.customerName"];
    const [orders,setOrders] = useState ();

    useEffect(() => {
        getAllOrders();
      }, []);

    function getAllOrders() {
        log.debug("ShowOrders - getAllOrders");
        CallgetAllOrders(login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug(response);
            setOrders(response.orders);
        });
    }  

    function onClickTable (e) {
        log.debug("clicked : " + e.currentTarget.id);
        orderSelected(orders.find(({id}) => id === e.currentTarget.id));
    }

    function displayData () {
        if (!orders) return('');
        const ret = orders.map((order) => {
            const name = order.customer?.firstname + " " + order.customer?.lastname;
            return (
                <tr key={order.id}>
                    <TableRowCell first="true" value={order.orderDate} onClick={onClickTable} id={order.id}/>
                    <TableRowCell value={order.totalAmount}/>
                    <TableRowCell value={order.totalPaid}/> 
                    <TableRowCell label={getLabelFromList(resources.orderStates,order.state)}/>                   
                    <TableRowCell value={name}/>
                </tr>
            )
        }) 
        return (ret);
    }

    return (
        <div className="ShowOrders">
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.orders"}/>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>   
    )
}