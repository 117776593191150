import React, { useCallback, useState } from 'react';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import PropTypes from 'prop-types';
import FormButton from '../layout/forms/FormButton';
import { log } from '../../Log';
import { CallGetCompany } from '../EUService/CallVies';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormLineSelect from '../layout/forms/FormLineSelect';
import resources from '../../resources/lists/dropdownlist.json'
import Address from './Address';

GetCompany.propTypes = {
    header: PropTypes.string,
    updateCompany: PropTypes.func.isRequired,
    updateSecundairyAddress: PropTypes.func,
    updateCompanyAddress: PropTypes.func,
    editMode: PropTypes.bool,
    company: PropTypes.object,
    address: PropTypes.object,
    addressHeader: PropTypes.string,
    addressButton: PropTypes.string,
    editVATNumber: PropTypes.bool,
    showAddressHeader: PropTypes.bool,
    otherAddressHeader: PropTypes.string,
    allowNonEU: PropTypes.bool
};

GetCompany.defaultProps = {
    editMode: false,
    editVATNumber: false,
    showAddressHeader: true,
    allowNonEU: false
};

export default function GetCompany({
    header,
    addressHeader,
    addressButton,
    updateCompany,
    updateSecundairyAddress,
    updateCompanyAddress,
    editMode,
    editVATNumber,
    showAddressHeader,
    allowChangeNonEU,
    otherAddressHeader,
    company = {},
    address = {}
}) {
    const [VATNumber,setVATNumber] = useState(company.VATNumber ? company.VATNumber : '');
    const [error,setError] = useState({message: '',code: '', errorJSON: {}});
    const [showSecundairyAddress, setShowSecundairyAddress] = useState(address && Object.keys(address).length > 0 ? true : false);
    const addrHeader = addressHeader ? addressHeader : "title.companyAddress";
    const otherAddress = addressButton ? addressButton : "button.addContactAddress";
    const otherAddrHeader = otherAddressHeader ? otherAddressHeader : "title.contactAddress";

    const searchHandler = useCallback(() => {
        log.debug("searchHandler");
        log.debug(company);
        CallGetCompany('',VATNumber,response => {
            log.debug(response);
            if (response.err || response.errCode) {
                setError({message: response.err,code: response.errCode, errorJSON: {VATNumber}});
            } else if (!response.valid) {
                setError({message: "number invalid",code: "INVALID", errorJSON: {VATNumber}});
            } else {
                setError({message: '',code: '' ,errorJSON: {}});
                const retComp = {
                                    VATNumber: response.countryCode + "" + response.vatNumber,
                                    name:response.name,
                                    nonEU: false,
                                    mainAddress: {
                                        street: response.officialAddress?.street,
                                        postalCode: response.officialAddress?.postalCode,
                                        city: response.officialAddress?.city,
                                        country: response.countryCode
                                    }
                                };
                updateCompany(retComp);
            }
        }); 
    }, [VATNumber,updateCompany]);

    const changeHandler = useCallback((e) => {
        setVATNumber(e.value);
        updateCompany({VATNumber: e.value});
    }, [setVATNumber,updateCompany]);

    const setNonEU = () => {
        updateCompany({nonEU: true});
    };

    const setEU = () => {
        updateCompany({nonEU: false}); 
        searchHandler();   
    }

    const showOtherAddressHandler = () => {
        if (showSecundairyAddress) {
            updateSecundairyAddress(null);
        } else if (!address || Object.keys(address).length <= 0) {
            updateSecundairyAddress({country: company.mainAddress.country});
        }
        setShowSecundairyAddress(!showSecundairyAddress);
    }

    const checkForSecundairyAddress = () => {
        if (updateSecundairyAddress && company.mainAddress && Object.keys(company.mainAddress).length > 0) {
            if (showSecundairyAddress) {
                return (
                    <>
                        <Address 
                            address={address} 
                            updateAddress={updateSecundairyAddress}
                            country={company?.mainAddress?.country}
                            header={otherAddrHeader}
                            readOnly={!editMode}
                        />
                        <div className='row'>
                        <div className='col-md-3'>
                            { editMode && <FormButton
                                onClick={showOtherAddressHandler} 
                                level='primary' 
                                label='button.reset'
                            /> }
                        </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <div className='col-md-3'>
                        { editMode && <FormButton
                            onClick={showOtherAddressHandler} 
                            level='primary' 
                            label={otherAddress}
                        /> } 
                    </div>
                )
            }
        } else {
            return null;
        }
   }   

    return (
        <div className="CompanyData mb-5">
            <FormLineParagHeader label={header} />
            <div className='row'>
                <FormLineInput
                    col={3}
                    label="label.VATNumber"
                    name="VATNumber"
                    readOnly={!editMode || !editVATNumber}
                    value={VATNumber}
                    onChange={changeHandler}
                    required
                />
                <div className='col-md-3'>
                    {editVATNumber && editMode && !company.nonEU && <FormButton
                        onClick={searchHandler} 
                        level='primary' 
                        label='button.search'
                        />
                    }
                    {!editVATNumber && editMode && !company.nonEU && <FormButton
                        onClick={searchHandler} 
                        level='primary' 
                        label='button.update'
                        />
                    }
                    {editVATNumber && allowChangeNonEU && editMode && !company.nonEU && <FormButton
                        onClick={setNonEU}
                        level='secondary'
                        label='button.nonEU'
                        />
                    }
                    {editVATNumber && editMode && company.nonEU && <FormButton
                        onClick={setEU}
                        level='secondary'
                        label='button.EU'
                        />
                    }
                </div>
                <FormLineInput
                    col={6}
                    label="label.companyName"
                    name="name"
                    readOnly={!editMode || !company.nonEU}
                    value={company.name}
                    updateObject={updateCompany}
                />
            </div>
            <FormLineErrorMessage error={error}/>
            {
                showAddressHeader && <FormLineParagHeader label={addrHeader} />
            }
            <div className='row'>
                <FormLineInput  
                    col={5}
                    label="label.street" 
                    name="street" 
                    readOnly={!editMode || !company.nonEU}
                    value={company?.mainAddress?.street || ''}
                    updateObject={updateCompanyAddress}
                /> 
                <FormLineInput  
                    col={2}
                    label="label.postalCode" 
                    name="postalCode" 
                    readOnly={!editMode || !company.nonEU} 
                    value={company?.mainAddress?.postalCode || ''}
                    updateObject={updateCompanyAddress}
                /> 
                <FormLineInput  
                    col={3}
                    label="label.city" 
                    name="city" 
                    readOnly={!editMode || !company.nonEU} 
                    value={company?.mainAddress?.city || ''}
                    updateObject={updateCompanyAddress}
                /> 
                <FormLineSelect  
                    col={2}
                    label="label.country" 
                    name="country" 
                    list={resources.addressCountries}
                    readOnly={!editMode || !company.nonEU} 
                    value={company?.mainAddress?.country || ''}
                    updateObject={updateCompanyAddress}
                /> 
            </div>
            {checkForSecundairyAddress()}
        </div>
    );
}