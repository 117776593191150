import React, { useState,useContext } from 'react';
import { AppContext } from '../../Drinkereez';
//import { useTranslation } from "react-i18next";
import { log } from '../../../Log';
import PropTypes from 'prop-types';
import FormLineInput from './FormLineInput';
import { CallUploadFile } from '../../UploadFile/CallUploadFile';
import FormButton from './FormButton';
import { openWindow } from '../../GeneralFunc';

FormLineFileInputV2.propTypes = {
    label: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    file: PropTypes.object,
    setError: PropTypes.func,
    updateFile: PropTypes.func
};

export default function FormLineFileInputV2 ({ 
    label, 
    readOnly, 
    file, 
    required,
    setError,
    updateFile
}) {
    //const { t } = useTranslation();
    const { login,client } = useContext(AppContext);
    const [modifyFile, setModifyFile] = useState(false);
    const [fileToUpload, setFileToUpload] = useState(null);
    const name = file?.name || '';

    function uploadFile(e) {
        const fileTU = e.target.files[0];
        if (fileTU) {
            log.debug(fileTU);
            setFileToUpload(fileTU);
        }
    }

    function modifyHandler() {
        setModifyFile(true);
    }

    function openHandler() {
        openWindow(file.url);
    }

    function saveFile() {
        CallUploadFile(login, fileToUpload, client.id, (response) => {
            if (response.err) {
                log.debug("error : " + response.err);
                setError({ errorMessage: response.err, errorJSON: { fileToUpload, login } });
            } else {
                log.debug("ok");
                log.debug(response);
                const document = {
                    url: response.path,
                    type: response.fileExtension,
                    name: response.name,
                    savedAs: response.savedAs
                };
                updateFile({ document: document });
                setModifyFile(false);
            }
        });
    }

    function addCancelButton() {
        if (file && file.url && modifyFile) {
            return (
                    <FormButton
                        onClick={() => setModifyFile(false)}
                        level='secondary'
                        label='button.cancel'
                        col={1}
                    />
            )
        }
    }

    function showLoadFile() {
        if (readOnly) {
            if (file && file.url) {
                return (
                        <FormButton
                            onClick={openHandler}
                            level='primary'
                            label='button.open'
                            col={1}
                        />
                )
            }
            return;
        }
        if (file && file.url && !modifyFile) {
            return (
                <>
                    <FormButton
                        onClick={openHandler}
                        level='primary'
                        label='button.open'
                        col={1}
                    />
                    <FormButton
                        onClick={modifyHandler}
                        level='secondary'
                        label='button.update'
                        col={1}
                    />
                </>
            )
        }
        return (
            <>
            <div className="col-sm-5">
                <input 
                    type="file"
                    className="form-control"
                    readOnly={readOnly}
                    onChange={uploadFile}
                    required={required}
                    // The `value` prop on file input is read-only and setting it can cause an error.
                    // Removed the `value` prop as it's not valid for file inputs and can lead to warnings/errors.
                />
            </div>
            <FormButton
                onClick={saveFile}
                level='primary'
                label='button.save'
                col={1}
            />
            {addCancelButton()}
            </>
        )
    }

    return (
        <div className="form-group row my-2">
            <FormLineInput
                col={3}
                label={label}
                name="name"
                readOnly={readOnly}
                value={name}
                required={required}
            />
            {showLoadFile()}
        </div>
    );
}