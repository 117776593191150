import React, {useContext,useEffect,useState} from 'react';
import { useTranslation } from "react-i18next";
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import TableLayout from '../layout/tables/TableLayout';
import FormButton from '../layout/forms/FormButton';
import resources from '../../resources/lists/dropdownlist.json';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import TableRowCellInput from '../layout/tables/TableRowCellInput';
import TableRowCellSelect from '../layout/tables/TableRowCellSelect';
import TableRowCellButton from '../layout/tables/TableRowCellButton';
import { transformRecords,getRecordsByVariableName,orderByField } from '../GeneralFunc';
import { CallSaveLinkNatCodeEUCode} from './CallNationalCode';
import FormLineSelect from '../layout/forms/FormLineSelect';
import TableRowCell from '../layout/tables/TableRowCell';
import { getLabelFromList } from '../GeneralFunc';
import PropTypes from 'prop-types';

ShowLinkNatCodeEUCode.propTypes = {
    linksNatCodeEUCode: PropTypes.any, // Replace 'any' with the specific type you expect
    addOrUpdateLinkNatCodeEUCode: PropTypes.any, // Replace 'any' with the specific type you expect
    natCodes: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ShowLinkNatCodeEUCode(props) {
    const {linksNatCodeEUCode,addOrUpdateLinkNatCodeEUCode,natCodes} = props;
    const {t} = useTranslation();
    const [viewLinksNatCodeEUCode,setViewLinksNatCodeEUCode] = useState(linksNatCodeEUCode);
    const [selectedLinkNatCodeEUCode,setSelectedLinkNatCodeEUCode] = useState ();
    const [searchOptions,setSearchOptions] = useState ({productType:"",sparklingType:"",packageType:""});
    const {login} = useContext(AppContext);
    const [forceRender, setForceRender] = useState(false); // eslint-disable-line no-unused-vars
    log.debug("ShowLinkNatCodeEUCode");
    log.debug(linksNatCodeEUCode);
    log.debug("viewLinksNatCodeEUCode :");
    log.debug(viewLinksNatCodeEUCode);

    useEffect(() => { 
        log.debug("ShowLinkNatCodeEUCode updated");
        setViewLinksNatCodeEUCode(linksNatCodeEUCode);
        setForceRender(prevValue => !prevValue); 
    },[linksNatCodeEUCode]);

    function addHandler () {
        log.debug("in ShowLinkNatCodeEUCode - addHandler");
        if (!selectedLinkNatCodeEUCode)
            setSelectedLinkNatCodeEUCode({id:"0",country:"BE",natCode:"",cnCode:"",epc:""});
    }

    function clearHandler () {
        log.debug("in ShowLinkNatCodeEUCode - clearHandler");
        setSearchOptions({productType:"",sparklingType:"",packageType:""});
    }

    function editHandler (e) {
        log.debug("in ShowLinkNatCodeEUCode - editHandler");
        setSelectedLinkNatCodeEUCode(linksNatCodeEUCode.find(({id}) => id === e.currentTarget.id));
    }

    function changeSearchHandler (e) {
        setSearchOptions({...searchOptions,[e.name]: e.value});
    }

    function saveHandler () {
        log.debug("in ShowLinkNatCodeEUCode - saveHandler");

        CallSaveLinkNatCodeEUCode(login,selectedLinkNatCodeEUCode,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                //setError({errorMessage: response.err + ' ' + response.ret?.err, errorJSON: {login,selectedNationalCode}});
                return;
            }
            log.debug(response);
            setSelectedLinkNatCodeEUCode();
            addOrUpdateLinkNatCodeEUCode(response);
        });
    }

    function getNatCodesList () {
        var natCs = natCodes;
        if (searchOptions?.productType!=="") natCs = getRecordsByVariableName(natCs,"productType",searchOptions.productType);
        if (searchOptions?.sparklingType!=="") natCs = getRecordsByVariableName(natCs,"sparklingType",searchOptions.sparklingType);
        if (searchOptions?.packageType!=="") natCs = getRecordsByVariableName(natCs,"packageType",searchOptions.packageType);
        return transformRecords(orderByField(natCs,"natCode"),"natCode");
    }

    function getNatCodeDescription (id) {
        if (!id || !natCodes) return;
        const natCode = natCodes.find(record => record.id === id);
        var descr="";
        if (natCode.productType) descr = descr + t(getLabelFromList(resources.generalProductTypes,natCode.productType));
        if (natCode.sparklingType && natCode.sparklingType!=="SPARKLING_TYPE_NOT_APPLICABLE") descr = descr + ", " + t(getLabelFromList(resources.sparklingTypes,natCode.sparklingType));
        if (natCode.packageType) descr = descr + ", " + t(getLabelFromList(resources.packageTypes,natCode.packageType));
        if (natCode.abvFrom && natCode.abvFrom!==-1) {
            descr = descr + ", " + natCode.abvFrom + "% " + t("label.till");
            if (natCode.abvTo && natCode.abvTo!==-1) descr = descr + " " + natCode.abvTo + "%";
            else descr = descr + " ...";
        }
        if (natCode.productionHLFrom && natCode.productionHLFrom!==-1) {
            descr = descr + ", " + natCode.productionHLFrom + "hl " + t("label.till");
            if (natCode.productionHLTo && natCode.productionHLTo!==-1) descr = descr + " " + natCode.productionHLTo + "hl";
            else descr = descr + " ...";
        }
        return descr;
    }

    function diplayRow (linkNatCodeEUCode) {
        const id = linkNatCodeEUCode.id;
        const natCode = (selectedLinkNatCodeEUCode?.id===id) ? selectedLinkNatCodeEUCode.natCode : linkNatCodeEUCode.natCode;
        const cnCode = (selectedLinkNatCodeEUCode?.id===id) ? selectedLinkNatCodeEUCode.cnCode : linkNatCodeEUCode.cnCode;
        const epc = (selectedLinkNatCodeEUCode?.id===id) ? selectedLinkNatCodeEUCode.epc : linkNatCodeEUCode.epc;
        const readOnly = (selectedLinkNatCodeEUCode?.id===linkNatCodeEUCode.id) ? false : true;
        const oneSelected = (selectedLinkNatCodeEUCode) ? true : false;
        const natCodesList = getNatCodesList();
        const isIdInList = natCodesList.some(record => record.value === natCode);
        if (selectedLinkNatCodeEUCode?.id!==id && !isIdInList) return;
        return (
            <tr key={linkNatCodeEUCode.id}>
                <TableRowCellInput name="cnCode" value={cnCode} id={id} readOnly={readOnly} onChange={changeHandler}/>
                <TableRowCellSelect id={id} list={resources.EPC} name="epc" value={epc} onChange={changeHandler} readOnly={readOnly}/>                
                <TableRowCellSelect id={id} list={natCodesList} name="natCode" value={natCode} onChange={changeHandler} readOnly={readOnly}/>                
                <TableRowCell value={getNatCodeDescription(natCode)}/>
                {
                    !oneSelected && readOnly && <TableRowCellButton id={id} onClick={editHandler} label="button.edit"/>
                }
                {
                    !readOnly && <TableRowCellButton id={id} onClick={saveHandler} label="button.save"/>
                }
            </tr>
        )
    }

    function displayData () {
        log.debug(viewLinksNatCodeEUCode);
        if (!viewLinksNatCodeEUCode) return('');
        var ret = viewLinksNatCodeEUCode.map((linkNatCodeEUCode) => {
            return (diplayRow(linkNatCodeEUCode));
        })
        if (selectedLinkNatCodeEUCode && selectedLinkNatCodeEUCode.id==="0") {
            ret.unshift(diplayRow(selectedLinkNatCodeEUCode)); 
        }
        return (ret);
    }

    function getColumns () {
        var columns = ["label.cnCode","label.epc","label.natcode","label.description"];
        return columns;
    }

    function changeHandler (e) {
        log.debug("e " + e.id + " " + e.name + " " + e.value);
        if (e.id===selectedLinkNatCodeEUCode.id)
            setSelectedLinkNatCodeEUCode({...selectedLinkNatCodeEUCode,[e.name]: e.value});
    }

    return (
        <div className="ShowLinksNatCodeEUCode">
            <div className='row'>
                <FormLineParagHeader col='6' label={"title.linkNatCodeEUCode"}/>
            </div>
            <div className='row'>
                <FormLineSelect col="2" list={resources.generalProductTypes} name="productType" label="label.productType" value={searchOptions.productType} onChange={changeSearchHandler}/>
                <FormLineSelect col="2" list={resources.sparklingTypes} name="sparklingType" label="label.sparklingType" value={searchOptions.sparklingType} onChange={changeSearchHandler}/>
                <FormLineSelect col="2" list={resources.packageTypes} name="packageType" label="label.packageType" value={searchOptions.packageType} onChange={changeSearchHandler}/>
            </div>
            <div className='row'>
                <div className='col-md-2'>
                    <FormButton onClick={addHandler} label='button.add'/>
                </div>
                <div className='col-md-2'>
                    <FormButton onClick={clearHandler} label='button.reset'/>
                </div>
            </div>
            <TableLayout columns={getColumns()} displayData={displayData}/>
        </div>   
    )
}